@import "src/styles/index";

body {
  margin: 0;
}

.container {
  background: var(--primary-color);
}

.footer {
  padding: 68px calc((50% - 343px)/2);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  display: block;
  width: 64px;
  height: auto;
}

.footer_logo_fasie {
  display: block;
  width: 128px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.left {
  align-self: center;
  display: flex;
  justify-content: center;
  gap: 11vw;
}

.links {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.link {
  font-family: var(--font-family-main);
  color: var(--color-text);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  text-decoration: none;
}

.right {
  display: flex;
  flex-direction: column;
}

.rightHeader {
  font-family: var(--font-family-header);
  color: var(--color-text);
  margin-bottom: 11px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
  text-align: left;
}

.btn {
  font-family: var(--font-family-main);
  margin-top: 32px;
  border-radius: 32px;
  width: 100%;
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  background-color: white;
  border: 0;
  padding: 16px calc((100% - 113px)/2);
  cursor: pointer;
}


@media (min-width: 1860px) {
  .link {
    font-size: 1.2vw;
    line-height: 1.2vw;
  }
  .rightHeader {
    font-size: 1.8vw;
    line-height: 1.8vw;
  }
  .btn {
    font-size: 1.2vw;
    line-height: 1.2vw;
    padding: 16px 0;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .logo {
    display: none;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 68px calc((100% - 343px)/2);
    flex-direction: column-reverse;
    gap: 74px;
    justify-content: center;
  }
  .left {
    align-self: start;
    display: flex;
    justify-content: center;
    gap: 11vw;
  }
}

@media (max-width: 596px) {
  .footer {
    padding: 52px 16px;
  }
}

@media (max-width: 375px) {
  .footer {
    padding: 52px 16px;
  }
}