@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700;800&display=swap');

:root {
  // Colors
  --bg-color: #f9f9fa;
  --primary-color: #49438A;
  --header-color: #938BB7;
  --secondary-color: #EDE8FF;
  --color-text: #FBF7FF;
  --hashtag-text-color: #FFF7F7;

  // Additional colors
  --gray100: #fefefe;
  --gray200: #fbfbfb;
  --gray300: #fafafa;
  --gray400: #f3f3f3;
  --gray500: #eee;
  --gray600: #a3a3a3;
  --gray700: #8c8c8c;
  --gray800: #555;
  --gray900: #333;
  --white: #fff;
  --black: #000;

  // Z-index - visibility context
  --zindex-sidebar: 900;
  --zindex-dropdown: 1000;
  --zindex-sticky: 1020;
  --zindex-fixed: 1030;
  --zindex-modal-backdrop: 1040;
  --zindex-modal: 1050;
  --zindex-popover: 1060;
  --zindex-tooltip: 1070;
}
