@import "src/styles/index";

body {
  margin: 0;
}

.text {
  position: relative;
  z-index: 1;
}

.right {
  width: calc(50vw - 172px);
  display: flex;
  align-items: center;
}

.container {
  height: calc(100vh - 172px);
  padding: 86px;
  gap: 86px;
  position: relative;
  background: var(--secondary-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.backForm {
  position: absolute;
  object-fit: contain;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.backFormSmall {
  display: none;
}

.left {
  width: calc(50vw - 172px);
}

.girlForm {
  position: absolute;
  left: 86px;
  width: 34vw;
  bottom: 0;
}

.heading {
  margin: 0;
  font-family: var(--font-family-header);
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
  text-align: left;
}

.subHeading {
  margin: 0;
  font-family: var(--font-family-main);
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
}

:global {
  .ant-form-item:last-child {
    margin-bottom: 0;
  }
  .ant-form {
    width: 100%;
  }
  .ant-input {
    resize: none !important;
    padding: 16px;
    border-radius: 24px;
    border: 1px solid var(--primary-color) !important;;
    gap: 10px;
    background-color: var(--secondary-color);
    width: 100%;
    font-family: var(--font-family-main);
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
  }
}

.btn {
  width: 100%;
  height: 70px;
  border-radius: 32px;
  text-align: center;
  background: var(--primary-color);
  font-family: var(--font-family-main);
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  border: none;
  color: var(--color-text);
  cursor: pointer;
}

@media (min-width: 1860px) {
  .container {
    padding: 7vw;
    height: calc(100vh - 14vw);
  }
  .text {
    margin-left: 3.5vw;
  }
  .girlForm {
    width: 32vw;
    left: 7vw;
  }
  .heading {
    font-size: 2.2vw;
    line-height: 2.2vw;
    margin-bottom: 16px;
  }
  .subHeading {
    font-size: 1.6vw;
    line-height: 1.6vw;
  }
  .btn {
    font-size: 1.6vw;
    line-height: 1.6vw;
  }
  :global {
    .ant-input {
      font-size: 1.6vw;
      line-height: 1.6vw;
    }
  }
}

@media (max-width: 992px) {
  .container {
    height: calc(100vh - 64px);
    padding: 32px 32px;
    gap: 32px;
  }
  .left {
    width: calc(50vw - 64px);
  }
  .right {
    width: calc(50vw - 64px);
  }
  .girlForm {
    left: 32px;
  }
  .btn {
    width: 100%;
    height: 50px;
  }
  :global {
    .ant-input {
      width: 100%;
      height: 50px;
    }
  }
}

@media (max-width: 768px) {
  .backForm {
    display: none;
  }

  .backFormSmall {
    display: block;
    z-index: 0;
    position: absolute;
    width: 100%;
    top: 128px;
    left: 0;
  }

  .right {
    display: block;
    width: 100%;
  }
  .left {
    width: 100%;
  }
  .btn {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    height: 50px;
  }
  :global {
    .ant-input {
      font-size: 16px;
      line-height: 20px;
      width: 100%;
      height: 50px;
    }
  }
  .girlForm {
    display: none;
  }
  .container {
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 40px;
    gap: 16px;
  }
  .subHeading {
    font-size: 16px;
    line-height: 20px;
  }
}


@media (max-width: 596px) {
  .container {
    height: calc(100vh - 80px);
    padding: 40px 16px;
  }
}