@import "src/styles/index";

body {
  margin: 0;
}

.container {
  width: 100%;
  height: 100%;
}

.whyUs {
  padding: 56px 40px;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: space-between;
}

.headerWithText {
  align-items: center;
  width: calc((100% - 64px)/4);
}

.simpleText {
  font-family: var(--font-family-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.headerText {
  font-family: var(--font-family-main);
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 20px;
}

.bigText {
  margin-right: 14px;
  height: 100%;
  display: flex;
  align-items: center;
  font-family: var(--font-family-header);
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
}

.howToUse {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 40px;
}

.firstStep {
  max-width: 100%;
  display: flex;
  flex-direction: row;
}

.secondStep {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.thirdStep {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.leftPart {
  width: 50%;
  display: flex;
  justify-content: center;
}

.rightPart {
  width: 50%;
  display: flex;
  justify-content: center;
}

.phone1 {
  width: 40vw;
  margin-left: 5vw;
}

.girl2 {
  width: 40vw;
}

.phone2 {
  width: 33vw;
}

.bigNum {
  margin: 0;
  font-family: var(--font-family-main);
  font-size: 48px;
  font-weight: 200;
  line-height: 60px;
  letter-spacing: 0;
  text-align: left;
}

.text {
  margin: 0;
  font-family: var(--font-family-main);
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0;
  text-align: left;
}

.description {
  position: relative;
  max-width: 33vw;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 24px;
}

.smallHeader {
  display: none;
}

.bigHeader {
  margin-top: 32px;
  display: flex;
  width: calc(100%);
  font-family: var(--font-family-header);
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0;
  text-align: left;
  p {
    margin: 0;
  }
}

.specialDescription {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
}

.fireWork {
  position: absolute;
  top: calc(100% + 40px);
  left: calc(50% - 24.45px);
  width: 48.9px;
  height: 60.94px;
}

.arrow {
  position: absolute;
  top: calc(100% + 40px);
  left: calc(50% - 31.21px);
  width: 62.42px;
  height: 37.6px;
}

.heart {
  position: absolute;
  top: calc(100% + 40px);
  left: calc(50% - 43.5px);
  width: 87px;
  height: 71px;
}

.tryContainer {
  align-items: center;
  text-align: center;
  width: calc(80%);
  align-self: center;
  padding: 20% 10% 16% 10%;
}

.link {
  font-family: var(--font-family-main);
  font-size: 24px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  margin-bottom: 5%;
}

.tryHeader {
  font-family: var(--font-family-header);
  margin-bottom: 11px;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0;
}

.button {
  position: relative;
  font-family: var(--font-family-main);
  background-color: var(--primary-color);
  width: 386px;
  height: 70px;
  padding: 20px 25px 20px 0;
  border-radius: 32px;
  border: 0;
  cursor: pointer;
  align-items: center;
  text-align: center;
  margin-bottom: 2%;
}

.buttonText {
  text-align: center;
  font-family: var(--font-family-main);
  color: var(--color-text);
  font-size: 24px;
  max-width: 100%;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0;
}

.buttonImg {
  position: absolute;
  top: 11px;
  right: 11px;
}

.footnote {
  font-family: var(--font-family-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  margin-bottom: 7%;
}

@media (min-width: 1860px) {
  .description {
    gap: 1.6vw;
  }
  .howToUse {
    padding-bottom: 10%;
  }
  .whyUs {
    padding: 10% 10% 6% 10%;
  }
  .fireWork {
    top: calc(100% + 40px);
    left: calc(50% - 3vw);
    width: 3vw;
    height: auto;
  }

  .arrow {
    position: absolute;
    top: calc(100% + 40px);
    left: calc(50% - 3vw);
    width: 5vw;
    height: auto;
  }

  .heart {
    position: absolute;
    top: calc(100% + 40px);
    left: calc(50% - 3vw);
    width: 5vw;
    height: auto;
  }

  .bigHeader {
    font-size: 2.2vw;
    line-height: 2.2vw;
  }
  .bigText {
    font-size: 2.2vw;
    line-height: 2.2vw;
  }
  .tryHeader {
    font-size: 2.2vw;
    line-height: 2.2vw;
  }
  .simpleText {
    font-size: 1.2vw;
    line-height: 1.2vw;
  }
  .headerText {
    margin-bottom: 1.6vw;
    font-size: 1.6vw;
    line-height: 1.6vw;
  }
  .text {
    font-size: 2vw;
    line-height: 2vw;
  }
  .bigNum {
    font-size: 3vw;
    line-height: 3vw;
  }
  .button {
    height: auto;
    border-radius: 3.9vw;
    padding: 1.9vw 0;
    width: 100%;
    text-align: center;
  }
  .buttonImg {
    width: 3.9vw;
    height: 3.9vw;
    top: calc((5.7vw - 3.9vw)/2);
    right: calc((5.7vw - 3.9vw)/2);
  }
  .buttonText {
    font-size: 1.9vw;
    line-height: 1.9vw;
  }
  .link {
    font-size: 2vw;
    line-height: 2vw;
  }
  .footnote {
    font-size: 1.5vw;
    line-height: 1.5vw;
  }
}

@media (max-width: 992px) {
  .button {
    padding: 12px 18px 12px 0;
    width: 35vw;
    height: 54px;
  }

  .buttonText {
    font-family: var(--font-family-main);
    color: var(--color-text);
    font-size: 18px;
    line-height: 20px;
  }

  .buttonImg {
    width: 42px;
    top: 5.5px;
    right: 5.5px;
  }
  .link {
    font-size: 20px;
  }
  .footnote {
    font-size: 15px;
  }
  .bigHeader {
    margin-left: -24px;
    margin-top: 24px;
    width: calc(100%);
    font-size: 28px;
    line-height: 28px;
  }
  .tryHeader {
    font-size: 28px;
    line-height: 28px;
  }
  .headerWithText {
    align-items: center;
    width: calc((100% - 94px)/4);
    min-width: 282px;
  }
  .whyUs {
    flex-wrap: wrap;
    gap: 30px;
  }
  .bigText {
    align-items: start;
  }

  .bigNum {
    font-size: 40px;
    font-weight: 200;
  }

  .text {
    font-size: 20px;
  }

  .fireWork {
    top: calc(100% + 24px);
    left: calc(50% - 18px);
    width: 36px;
  }

  .arrow {
    top: calc(100% + 24px);
    left: calc(50% - 24px);
    width: 48px;
  }

  .heart {
    top: calc(100% + 24px);
    left: calc(50% - 31px);
    width: 62px;
  }
}

@media (max-width: 768px) {
  .bigHeader {
    margin-left: -16px;
    margin-top: 24px;
    width: 100%;
    font-size: 26px;
    line-height: 26px;
  }
  .tryHeader {
    font-size: 26px;
    line-height: 26px;
  }
  .headerWithText {
    align-items: center;
    width: calc((100% - 94px) / 4);
    min-width: 242px;
  }
  .phone1 {
    width: 50vw;
    margin-left: 7vw;
  }

  .girl2 {
    width: 50vw;
  }

  .phone2 {
    width: 40vw;
  }

  .bigNum {
    font-size: 36px;
  }

  .text {
    font-size: 18px;
  }
  .link {
    font-size: 18px;
  }
  .footnote {
    font-size: 14px;
  }

  .description {
    max-width: 45vw;
  }
}

@media (max-width: 596px) {

  .button {
    bottom: calc(32%);
    width: calc(60vw);
    height: 52px;
  }

  .buttonText {
    font-size: 16px;
    line-height: 20px;
  }

  .buttonImg {
    top: 5px;
    right: 5px;
  }
  .specialDescription {
    width: 100%;
  }
  .headerWithText {
    height: 15vh;
  }
  .whyUs {
    padding: 40px 0px;
    justify-content: center;
    align-content: center;
    height: calc(100vh - 80px);
    gap: calc((40vh - 80px)/10);
  }

  .bigText {
    margin-bottom: 16px;
  }

  .fireWork {
    top: 100%;
    left: calc(100% - 80px);
    width: 28px;
  }

  .arrow {
    top: calc(100% + 12px);
    rotate: 6.53deg;
    left: 37px;
    width: 40px;
  }

  .heart {
    top: 100%;
    left: calc(100% - 62px);
    width: 44px;
  }

  .bigHeader {
    display: none;
  }
  .headerWithText {
    width: calc(100% - 32px);
  }

  .smallHeader {
    display: block;
    margin-left: 16px;
    margin-bottom: 32px;
    font-family: var(--font-family-header);
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0;
    text-align: left;
  }
  .tryHeader {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0;
  }

  .bigText {
    text-align: left;
  }

  .phone1 {
    width: 80vw;
    margin-left: 0;
  }

  .girl2 {
    width: 80vw;
  }

  .phone2 {
    width: 80vw;
  }

  .bigNum {
    font-size: 32px;
    line-height: 40px;
  }

  .text {
    font-size: 16px;
    line-height: 20px;
  }
  .link {
    font-size: 16px;
    line-height: 20px;
  }
  .footnote {
    font-size: 12px;
    line-height: 15px;
  }

  .firstStep {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
    margin: 0 16px;
  }

  .secondStep {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0 16px;
  }

  .thirdStep {
    margin: 0 16px;
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }

  .description {
    max-width: calc(100vw - 32px);
  }

  .leftPart {
    width: 100%;
    justify-content: center;
  }

  .rightPart {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 375px) {

}