@import "./variables/global";
@import "./themes/normal";

.app {
  max-width: 100vw;
  overflow: hidden;
  min-height: 100vh;
  font-size: var(--font-m);
  color: var(--color-text);
  background: var(--bg-color);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #72849A66; 
  border-radius: 10px;
}
