@import "src/styles/index";

.container {
  max-width: 100vw;
  height: 100%;
  background-color: var(--header-color);
}

.body {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  position: relative;
  gap: 140px;
}

.backBig1 {
  position: absolute;
  object-fit: contain;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.backBig2 {
  z-index: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.backSmall1 {
  display: none;
}

.backSmall2 {
  display: none;
}

.contactUs {
  position: absolute;
  cursor: pointer;
  color: var(--color-text);
  right: 2.4vw;
  top: 2vw;
  z-index: 4;
  font-family: var(--font-family-main);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  text-decoration: underline;
}

.logo {
  z-index: 2;
  width: 4.5vw;
  position: absolute;
  left: 2.4vw;
  top: 2vw;
  height: auto;
}

.hashtags::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.hashtags {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  z-index: 100;
  width: 60%;
  overflow-x: scroll;
  display: flex;
  flex-direction: row;
  gap: 16px;
  position: absolute;
  bottom: 32px;
  right: 40px;
}

.hashtagText {
  white-space: nowrap;
  font-family: var(--font-family-main);
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  color: var(--hashtag-text-color);
}

.left {
  overflow: hidden;
  max-width: 100vw !important;
  max-height: 100%;
  z-index: 2;
}

.girl {
  margin-bottom: -4px;
  width: 36vw;
  height: auto;
  z-index: 2;
}

.header {
  z-index: 2;
  font-family: var(--font-family-header);
  color: var(--color-text);
  font-size: 80px;
  font-weight: 800;
  line-height: 96px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 20px;
}

.subHeader {
  z-index: 2;
  font-family: var(--font-family-main);
  color: var(--color-text);
  font-size: 24px;
  max-width: 100%;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0;
  text-align: left;
}

.right {
  z-index: 2;
  max-width: 40vw;
  justify-content: center;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 5vw;
}

.button {
  position: relative;
  font-family: var(--font-family-main);
  background-color: var(--primary-color);
  width: 386px;
  height: 70px;
  padding: 20px 25px 20px 0;
  border-radius: 32px;
  border: 0;
  cursor: pointer;
  align-items: center;
  text-align: center;
}

.buttonText {
  text-align: center;
  font-family: var(--font-family-main);
  color: var(--color-text);
  font-size: 24px;
  max-width: 100%;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0;
}

.buttonImg {
  position: absolute;
  top: 11px;
  right: 11px;
}

@media (min-width: 1860px) {
  .hashtags {
    width: 60%;
    bottom: 3vw;
    gap: 1.4vw;
  }
  .hashtagText {
    font-size: 1.4vw;
    line-height: 1.4vw;
  }
  .header {
    font-size: 6.6vw;
    line-height: 6.6vw;
  }
  .subHeader {
    font-size: 1.9vw;
    line-height: 1.9vw;
  }
  .contactUs {
    font-size: 1.9vw;
    line-height: 1.9vw;
  }
  .button {
    height: auto;
    border-radius: 3.9vw;
    padding: 1.9vw 0;
    width: 100%;
    text-align: center;
  }
  .buttonImg {
    width: 3.9vw;
    height: 3.9vw;
    position: absolute;
    top: calc((5.7vw - 3.9vw)/2);
    right: calc((5.7vw - 3.9vw)/2);
  }
  .buttonText {
    font-size: 1.9vw;
    line-height: 1.9vw;
  }
}

@media (max-width: 992px) {
  .button {
    padding: 12px 18px 12px 0;
    width: 35vw;
    height: 54px;
  }

  .buttonText {
    font-family: var(--font-family-main);
    color: var(--color-text);
    font-size: 18px;
    line-height: 20px;
  }

  .buttonImg {
    position: absolute;
    width: 42px;
    top: 5.5px;
    right: 5.5px;
  }

  .header {
    font-size: 42px;
    line-height: 48px;
  }

  .subHeader {
    font-size: 20px;
    line-height: 22px;
  }
  .contactUs {
    right: 2.4vw;
    top: 2vw;
    font-size: 16px;
    line-height: 20px;
  }
}

@media (max-width: 768px) {
  .hashtags {
    width: calc(100% - 32px);
    position: absolute;
    bottom: 24px;
    left: 16px;
    right: 16px;
    overflow-y: scroll;
  }
  .body {
    gap: 80px;
  }
}

@media (max-width: 596px) {
  .body {
    display: flex;
    justify-content: center;
    gap: 20px;
    overflow: hidden;
  }

  .backBig1 {
    display: none;
  }

  .backBig2 {
    display: none;
  }

  .backSmall1 {
    display: block;
    z-index: 0;
    position: absolute;
    width: 100%;
    top: 75px;
    left: 0;
  }

  .backSmall2 {
    display: block;
    z-index: 0;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  .logo {
    width: 44px;
    left: 16px;
    top: 28px;
  }

  .contactUs {
    right: 16px;
    top: 18px;
    font-size: 16px;
    line-height: 20px;
  }

  .girl {
    width: 100vw;
    object-fit: cover;
    margin-bottom: -4px;
    padding: 83px 0 0 0;
  }

  .text {
    max-width: 40%;
  }

  .right {
    position: absolute;
    display: block;
    height: 100%;
    max-width: 100vw;
    top: 17%;
    left: 16px;
  }

  .button {
    position: absolute;
    bottom: calc(32%);
    width: calc(100vw - 32px);
    height: 52px;
  }

  .buttonText {
    font-size: 16px;
    line-height: 20px;
  }

  .buttonImg {
    top: 5px;
    right: 5px;
  }

  .header {
    font-size: 40px;
    line-height: 48px;
  }

  .subHeader {
    font-size: 16px;
    line-height: 20px;
  }
  .hashtags {
    width: calc(100% - 32px);
    position: absolute;
    bottom: 40px;
    left: 16px;
    right: 16px;
    overflow-y: scroll;
  }
}

@media (max-width: 375px) {
  .text {
    max-width: 45%;
  }
}