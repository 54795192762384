:root {
  // Sizes

  // Font
  --font-family-header: consolas, "Nunito", serif;
  --font-family-main: consolas, "Montserrat", serif;
  --font-size-m: 16px;
  --font-line-m: 24px; // Usually 8px greater than font-size
  --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);
  --font-size-l: 24px;
  --font-line-l: 32px;
  --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

  // Media queries
  --sm: 375px;
  --md: 576px;
  --lg: 768px;
  --xl: 992px;
  --xxl: 1200px;
}
